// Gatsby supports TypeScript natively!
import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TopImage from "../components/topImage"

export default function Projects({ data }) {
  const projects = data.allMarkdownRemark.nodes
  const topImage = data.markdownRemark.topImage.childImageSharp.gatsbyImageData
  const title = data.markdownRemark.frontmatter.title
  return (
    <Layout>
      <Seo title="Projects" />
      <TopImage topImage = {topImage} title={title} />
      <div className="container mx-auto mt-6 w-9/12">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
          {projects.map(project => (
            <Link to={"/projects/" + project.frontmatter.path} key={project.id}>
              <div className="bg-white shadow-2xl rounded-3xl mb-5">
                <GatsbyImage
                  className="rounded-t-2xl flex items-center w-full aspect-video ..."
                  image={project.featuredImg.childImageSharp.gatsbyImageData}
                  alt="bla"
                />
                <div className="p-5">
                  <h5 className="text-gray-900 font-bold text-lg tracking-tight mb-3 no-underline">
                    {project.frontmatter.title}
                  </h5>
                  <p className="font-normal text-gray-700 mb-3">
                    {project.frontmatter.description}
                  </p>
                  <Link
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                    to={"/projects/" + project.frontmatter.path}
                    key={project.id}
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      nodes {
        id
        frontmatter {
          path
          title
          description
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { fit: COVER, cropFocus: CENTER }
              placeholder: BLURRED
            )
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/projectMainPage/" }) {
      frontmatter {
        title
      }
      topImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`
